// ==================================================
// Blocks
// ==================================================

// Content wrapper with title, content and button

.wrap {
  background: #f3f7fb;
  padding: 48px 0;
  position: relative;
  .backgroundblendmode & > .pattern {
    background-blend-mode: color-burn;
    background-color: #c6d9e8;
    background-image: url(../img/pattern.png);
    height: 100%;
    left: 0;
    opacity: 0.05;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    & ~ .container {
      position: relative;
      z-index: 2; } }
  // Background & Laces Colors
  &.light-blue {
    background: #a3d9f7;
    &:before,
    &:after {
      background-image: url(../img/lace/light-blue.png) !important; } }
  &.grey {
    background: #c6d9e8;
    &:before,
    &:after {
      background-image: url(../img/lace/grey.png) !important; } }
  &.white {
    background: #fff;
    &:before,
    &:after {
      background-image: url(../img/lace/white.png) !important; } }
  &.light-grey {
    background: #e7eaee;
    &:before,
    &:after {
      background-image: url(../img/lace/light-grey.png) !important; } }
  &.blue {
    background: #487abf;
    &:before,
    &:after {
      background-image: url(../img/lace/blue.png) !important; } }
  &.dark-blue {
    background: #1d2f47;
    &:before,
    &:after {
      background-image: url(../img/lace/dark-blue.png) !important; } }
  &.error {
    background-image: linear-gradient(to bottom, #c6d9e8, #f3f7fb);
    padding: 80px 0; }
  // Laces
  &.lace-top {
    padding-top: 30px;
    &:before {
      background-image: url(../img/lace/white.png);
      background-position: 0 0;
      background-repeat: repeat-x;
      content: "";
      height: 18px;
      left: 0;
      position: absolute;
      top: -18px;
      width: 100%;
      z-index: 1; } }
  &.lace-bottom {
    padding-bottom: 30px;
    &:after {
      background-image: url(../img/lace/white.png);
      background-position: 0 -18px;
      background-repeat: repeat-x;
      bottom: -18px;
      content: "";
      height: 18px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1; } }
  .title:not(.not-centered) {
    @extend .clearfix;
    text-align: center; }
  .content {
    @extend .clearfix; }
  .more {
    @extend .clearfix;
    text-align: center;
    .button {
      margin-right: 20px; } } }

#wrap-hero-card {
  height: 205px;
  position: relative;
  img {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); } }

// List of social networks

.social-networks-list {
  text-align: center;
  &.white {
    text-align: left;
    li {
      a {
        .icon {
          background: #fff;
          color: map-get($colors, primary); } } } }
  li {
    display: inline-block;
    &:not(:last-of-type) {
      margin-right: 10px; }
    a {
      @include rem(font-size, 12px);
      @include rem(line-height, 24px);
      display: block;
      font-family: "plutoregular";
      text-align: center;
      text-decoration: none;
      .icon {
        @include rem(font-size, 24px);
        @include rem(line-height, 54px);
        background: map-get($colors, primary);
        border-radius: 50%;
        color: #fff;
        display: block;
        height: 54px;
        margin: 0 auto 5px;
        width: 54px; } } } }

// Block product collection

.block-product-collection {
  background-image: linear-gradient(to bottom, #276ec3 0%, #276ec3 120px, #fff 121px, #fff 100%);
  display: block;
  height: 200px;
  padding: 25px 15px;
  position: relative;
  text-decoration: none;
  &:not(:last-of-type) {
    margin-bottom: 20px; }
  &.les-nouveautes:after,
  &.new-products:after {
    content: url(../img/collection/nouveautes.png); }
  &.les-classiques:after,
  &.classic-collection:after {
    content: url(../img/collection/classiques.png); }
  &.collection-estivale:after,
  &.regional-collection:after {
    content: url(../img/collection/collection-estivale.png); }
  &.collection-festive:after,
  &.seasonal-collection:after {
    content: url(../img/collection/collection-festive.png); }
  &.les-sales:after,
  &.savoury-collection:after {
    content: url(../img/collection/sales.png); }
  &:after {
    position: absolute;
    bottom: 15px;
    right: 5px; }
  h3 {
    @include rem(font-size, 20px);
    @include rem(line-height, 24px);
    color: #fff;
    font-family: "plutoregular";
    letter-spacing: -1px;
    margin: 0; } }

// Block recipe category

.block-recipe-category {
  background-image: linear-gradient(to bottom, #eed563, #e2b60a);
  border: 1px solid #eda606;
  display: block;
  padding: 15px;
  position: relative;
  text-decoration: none;
  &:not(:last-of-type) {
    margin-bottom: 20px; }
  &:first-of-type {
    h3:before {
      content: none; } }
  h3 {
    @include rem(font-size, 14px);
    @include rem(line-height, 71px);
    color: #0e1531;
    font-family: "plutoregular";
    margin: 0;
    position: relative;
    &:before {
      content: url(../img/title-icon-background.png);
      height: 71px;
      left: 0;
      position: absolute;
      top: 0;
      width: 71px; }
    &:after {
      content: ">";
      position: absolute;
      right: 0;
      top: 0; }
    .icon {
      color: #fff;
      display: inline-block;
      font-size: 30px;
      height: 71px;
      margin-right: 10px;
      position: relative;
      text-align: center;
      vertical-align: middle;
      width: 71px;
      z-index: 1;
      &.icon-gavottes {
        background-image: url(../img/logo.png);
        background-size: contain; } }
    .text {
      @include rem(line-height, 24px);
      display: inline-block;
      vertical-align: middle; } } }

// Block product

.block-product {
  display: block;
  margin-bottom: 30px;
  text-align: center;
  &:not(:last-of-type) {
    padding-bottom: 30px;
    border-bottom: 1px solid #c8d8de; }
  img {
    height: auto;
    margin-bottom: 30px;
    max-width: 100%; }
  h3 {
    @include rem(font-size, 24px);
    @include rem(line-height, 24px);
    font-family: "plutoregular"; }
  .body {
    margin-bottom: 30px; }
  .body p {
    @include rem(font-size, 12px);
    @include rem(line-height, 24px);
    margin: 0; } }

// Block text : story & gavottes

.block-text {
  display: block;
  &:not(:last-of-type) {
    margin-bottom: 30px; }
  .suptitle {
    @include rem(font-size, 40px);
    @include rem(line-height, 40px);
    color: map-get($colors, yellow);
    font-family: "plutoextralight";
    opacity: 0.5;
    margin-bottom: 10px; }
  .subtitle {
    @include rem(font-size, 24px);
    @include rem(line-height, 24px); }
  .body {
    margin-bottom: 30px; }
  .body p {
    @include rem(font-size, 12px);
    @include rem(line-height, 24px);
    color: #fff;
    margin: 0; }
  .picture {
    text-align: center;
    img {
      height: auto;
      margin-bottom: 30px;
      max-width: 100%; } } }

// Block news

.block-news {
  background: #fff;
  box-shadow: 0 0 2px 1px rgba(#212c38, 0.25);
  display: block;
  margin-bottom: 30px;
  text-decoration: none;
  .picture {
    background: #eee url(../img/watermark.png) no-repeat center;
    background-size: contain;
    height: 125px;
    overflow: hidden;
    position: relative;
    width: 100%;
    img {
      height: auto;
      max-width: 100%; }
    .external-link {
      border-bottom: 60px solid rgba(#000, 0.4);
      border-left: 60px solid transparent;
      bottom: 0;
      height: 0;
      position: absolute;
      right: 0;
      width: 0;
      .icon {
        @include rem(font-size, 18px);
        bottom: -50px;
        color: #fff;
        position: absolute;
        right: 7px; } } }
  .infos {
    overflow: hidden;
    padding: 12px;
    width: 100%;
    h3 {
      @include rem(font-size, 14px);
      @include rem(line-height, 24px);
      color: #3b4858;
      display: block;
      font-family: "plutoregular";
      height: 72px;
      margin: 0;
      overflow: hidden; }
    .date {
      @include rem(font-size, 11px);
      @include rem(line-height, 24px);
      color: #9d9d9d;
      display: block;
      font-family: "plutoregular";
      margin: 0; } } }

// Details news

.details-news {
  background: #fff;
  box-shadow: 0 0 2px 1px rgba(#212c38, 0.25);
  display: block;
  margin-bottom: 30px;
  .picture {
    background: #eee url(../img/watermark.png) no-repeat center;
    background-size: contain;
    height: 265px;
    overflow: hidden;
    width: 100%;
    img {
      height: auto;
      max-width: 100%; } }
  .infos {
    overflow: hidden;
    padding: 12px;
    width: 100%;
    h3 {
      @include rem(font-size, 14px);
      @include rem(line-height, 24px);
      color: #3b4858;
      display: block;
      font-family: "plutoregular";
      margin: 0;
      overflow: hidden; }
    .date {
      @include rem(font-size, 11px);
      @include rem(line-height, 24px);
      color: #9d9d9d;
      display: block;
      font-family: "plutoregular"; }
    .body,
    .body p {
      @include rem(font-size, 11px);
      @include rem(line-height, 18px);
      font-family: "plutoregular"; } } }

// Block recipe

.block-recipe {
  display: block;
  margin-bottom: 30px;
  text-decoration: none;
  &.details {
    background-color: #fff; }
  .block-recipe-link {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2; }
  .block {
    float: left;
    height: 443px;
    position: relative;
    width: 300px;
    &:after {
      background: url(../img/recipe-background.png) no-repeat top center;
      content: "";
      display: block;
      height: 461px;
      left: -9px;
      top: -9px;
      width: 318px;
      position: absolute;
      z-index: 0; } }
  .picture {
      background: #eee url(../img/watermark.png) no-repeat center;
      background-size: contain;
      height: 280px;
      overflow: hidden;
      position: relative;
      width: 100%;
      z-index: 1;
      img {
        height: auto;
        max-width: 100%; }
      .type {
        border-right: 90px solid transparent;
        border-top: 60px solid #f8d65a;
        height: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 0;
        .text {
          @include rem(font-size, 12px);
          @include rem(line-height, 12px);
          color: #0e1531;
          font-family: "plutoregular";
          position: absolute;
          top: -40px;
          left: 10px;
          transform: rotate(-35deg); } } }
  .infos {
    padding: 20px;
    position: relative;
    z-index: 1;
    h3 {
      @include rem(font-size, 16px);
      @include rem(line-height, 24px);
      color: #0e1531;
      font-family: "plutoregular";
      height: 48px;
      overflow: hidden;
      margin-bottom: 5px; }
    .recipe-infos {
      @include rem(font-size, 12px);
      @include rem(line-height, 24px);
      color: #882c94;
      font-family: "plutoregular";
      .icon {
        @include rem(font-size, 18px);
        @include rem(line-height, 24px);
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle; }
      .text {
        @include rem(line-height, 12px);
        display: inline-block;
        vertical-align: middle; } } }
  .details {
    float: right;
    height: 443px;
    overflow: scroll;
    padding: 20px;
    width: 300px;
    .share {
      margin-bottom: 20px; }
    div[class^=details-] {
      margin-bottom: 20px;
      p {
        margin-bottom: 0; }
      ul {
        margin-left: 40px;
        li {
          @include rem(font-size, 12px);
          @include rem(line-height, 14px);
          list-style: disc;
          list-style-position: outside;
          margin-bottom: 10px; } } } } }

// Block shop

.block-shop {
  background: linear-gradient(to bottom, #ffc500, #eca606);
  box-shadow: 0 0 40px 0 rgba(#000, .25);
  margin-top: 50px;
  padding: 10px 0 30px;
  text-align: center;
  h2 {
    @include rem(font-size, 40px);
    @include rem(line-height, 40px);
    border-top: 1px solid #3b4858;
    letter-spacing: 0;
    margin-bottom: 20px;
    padding-top: 20px;
    text-shadow: none; } }
