// ==================================================
// Buttons
// ==================================================

.button {
  @include rem(font-size, 12px);
  @include rem(line-height, 12px);
  appearance: none;
  background-image: linear-gradient(to right, #eca606, #ffc500);
  border: none;
  box-shadow: -1px 1px 2px 0px #eca606;
  color: #202a35;
  display: inline-block;
  font-family: "plutoregular";
  padding: 10px 15px;
  position: relative;
  text-align: center;
  text-decoration: none;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-left: 20px solid #ffc500;
    border-bottom: 16px solid transparent; }
  &.blue {
    background-image: linear-gradient(to right, #1c2532, #425062);
    box-shadow: -1px 1px 2px 0px #1c2532;
    color: #fff;
    &:after {
      border-left: 20px solid #425062; } } }

.back-button {
  margin-bottom: 20px;
  a {
    @include rem(font-size, 14px);
    @include rem(line-height, 24px);
    color: map-get($colors, primary);
    float: right;
    font-family: "plutoregular";
    text-decoration: none;
    text-transform: uppercase;
    .wrap.dark-blue &,
    .wrap.blue & {
      color: #fff; }
    .icon {
      @include rem(font-size, 18px);
      @include rem(line-height, 24px);
      display: inline-block;
      height: 24px;
      margin-right: 5px;
      vertical-align: middle; }
    .text {
      display: inline-block;
      vertical-align: middle; } } }

.sharer-button {
  @include rem(font-size, 10px);
  @include rem(line-height, 27px);
  border-radius: 3px;
  border: 1px solid #c8d8de;
  color: #4777bf;
  display: inline-block;
  font-family: "plutoregular";
  padding-right: 10px;
  text-decoration: none;
  .icon {
    @include rem(font-size, 16px);
    @include rem(line-height, 25px);
    background: #4777bf;
    color: #fff;
    display: inline-block;
    height: 25px;
    margin-right: 10px;
    vertical-align: middle;
    width: 25px; }
  .text {
    @include rem(line-height, 12px);
    display: inline-block;
    vertical-align: middle; } }
