// ==================================================
// Typography rules
// ==================================================

// General

html {
  font-size: 62.5%; // 1rem = 10px
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0.1);
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  @include rem(font-size, 16px);
  color: #3b4858;
  font-family: Arial, sans-serif; }

// Fonts

@font-face {
  font-family: "plutoregular";
  src: url('../fonts/pluto/plutoregular-webfont.eot');
  src: url('../fonts/pluto/plutoregular-webfont.eot?#iefix') format('eot'), url('../fonts/pluto/plutoregular-webfont.woff') format('woff'), url('../fonts/pluto/plutoregular-webfont.ttf') format('truetype'), url('../fonts/pluto/plutoregular-webfont.svg#novasolid') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "plutoextralight";
  src: url('../fonts/pluto/plutoextralight-webfont.eot');
  src: url('../fonts/pluto/plutoextralight-webfont.eot?#iefix') format('eot'), url('../fonts/pluto/plutoextralight-webfont.woff') format('woff'), url('../fonts/pluto/plutoextralight-webfont.ttf') format('truetype'), url('../fonts/pluto/plutoextralight-webfont.svg#novasolid') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "plutobold";
  src: url('../fonts/pluto/plutobold-webfont.eot');
  src: url('../fonts/pluto/plutobold-webfont.eot?#iefix') format('eot'), url('../fonts/pluto/plutobold-webfont.woff') format('woff'), url('../fonts/pluto/plutobold-webfont.ttf') format('truetype'), url('../fonts/pluto/plutobold-webfont.svg#novasolid') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "savoye";
  src: url('../fonts/savoye/savoyestd-webfont.eot');
  src: url('../fonts/savoye/savoyestd-webfont.eot?#iefix') format('eot'), url('../fonts/savoye/savoyestd-webfont.woff') format('woff'), url('../fonts/savoye/savoyestd-webfont.ttf') format('truetype'), url('../fonts/savoye/savoyestd-webfont.svg#novasolid') format('svg');
  font-weight: normal;
  font-style: normal; }

// Typography rules

a {
  color: map-get($colors, primary);
  cursor: pointer;
  text-decoration: underline;
  &.yellow {
    color: map-get($colors, yellow);
    html.no-touchevents &:hover {
      color: map-get($colors, yellow); } }
  html.no-touchevents &:hover {
    color: map-get($colors, primary);
    text-decoration: none; } }

b, strong {
  font-weight: bold; }

i, em {
  font-style: italic; }

u {
  @extend .text-underline; }

img[data-src] {
  opacity: 0.5;
  transition: opacity 250ms ease-in; }

h1 {
  @extend .clear-both;
  @include rem(font-size, 36px);
  line-height: normal;
  margin: 0 0 30px; }

h2 {
  @extend .clear-both;
  @include rem(font-size, 30px);
  line-height: normal;
  margin: 0 0 30px;
  a {
    html.no-touchevents &:hover {
      text-decoration: none; } } }

h1.with-icon,
h2.with-icon {
  @include rem(font-size, 16px);
  @include rem(line-height, 70px);
  color: map-get($colors, primary);
  display: inline-block;
  font-family: "plutoregular";
  position: relative;
  text-align: left;
  &:before {
    content: url(../img/title-icon-background.png);
    height: 71px;
    left: 0;
    position: absolute;
    top: 0;
    width: 71px; }
  .icon {
    color: #fff;
    display: inline-block;
    font-size: 30px;
    height: 71px;
    margin-right: 5px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 71px;
    z-index: 1; }
  .text {
    @include rem(line-height, 24px);
    display: inline-block;
    max-width: 160px;
    vertical-align: middle; } }

h1.cursive,
h2.cursive {
  @include rem(font-size, 52px);
  @include rem(line-height, 52px);
  font-family: "savoye";
  letter-spacing: -2px;
  text-shadow: 1px 1px #fff;
  &.white {
    color: #fff;
    text-shadow: 1px 1px #000; }
  &.small {
    @include rem(font-size, 36px);
    @include rem(line-height, 36px); } }

h3 {
  @extend .clear-both;
  @include rem(font-size, 26px);
  line-height: normal;
  margin: 0 0 30px;
  a {
    html.no-touchevents &:hover {
      text-decoration: none; } } }

h2.subtitle,
h3.subtitle,
h4.subtitle {
  @include rem(font-size, 30px);
  @include rem(line-height, 30px);
  color: map-get($colors, yellow);
  font-family: "plutoextralight";
  letter-spacing: -1px;
  text-align: left; }

h2.subsubtitle,
h3.subsubtitle,
h4.subsubtitle {
  @include rem(font-size, 16px);
  @include rem(line-height, 24px);
  color: #fff;
  font-family: "plutoregular";
  letter-spacing: -1px;
  text-align: left; }

h3.cursive,
h4.cursive {
  @include rem(font-size, 30px);
  @include rem(line-height, 30px);
  font-family: "savoye";
  margin-bottom: 20px;
  &.purple {
    color: #882c94; } }

h4 {
  @extend .clear-both;
  @include rem(font-size, 20px);
  @include rem(line-height, 24px);
  margin: 0 0 30px;
  a {
    html.no-touchevents &:hover {
      text-decoration: none; } } }

h5 {
  @extend .clear-both;
  @include rem(font-size, 16px);
  @include rem(line-height, 24px);
  margin: 0 0 30px;
  a {
    html.no-touchevents &:hover {
      text-decoration: none; } } }

p {
  @include rem(font-size, 12px);
  @include rem(line-height, 24px);
  margin: 0 0 25px; }
