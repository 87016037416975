// ==================================================
// Rem
// Sass mixin and function to use rem units with pixel fallback
// https://github.com/pierreburel/rem
// ==================================================

$rem-baseline: 10px !default;

@function rem($values) {
  $rem: unquote("");
  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "px" {
      $rem: append($rem, $value / ($rem-baseline / 1rem)); }
    @else {
      $rem: append($rem, $value); } }
  @return $rem; }

@mixin rem($property, $values, $important: false) {
  $important: if($important, unquote(" !important"), null);
  #{$property}: append($values, $important);
  #{$property}: append(rem($values), $important); }


// ==================================================
// Text Replacement
// Inspired by Compass (http://compass-style.org/reference/compass/typography/text/replacement)
// ==================================================

$hide-text-direction: left !default;

@mixin hide-text($direction: $hide-text-direction) {
  @if $direction == left {
    $approximate-em-value: 12px / 1em;
    $wider-than-any-screen: -9999em;
    overflow: hidden;
    text-align: left;
    text-indent: $wider-than-any-screen * $approximate-em-value; }
  @else {
    overflow: hidden;
    text-indent: 110%;
    white-space: nowrap; } }


// ==================================================
// Horizontal, vertical or absolute centering of element within its parent
// If specified, this mixin will use negative margins based on element's
// dimensions. Else, it will rely on CSS transforms which have a lesser
// browser support but are more flexible as they are dimension-agnostic.
//
// @author Hugo Giraudel
//
// @param {Length | null} $width [null]  - Element width
// @param {Length | null} $height [null] - Element height
// ==================================================
@mixin center($height: null, $width: null) {
  left: 50%;
  position: absolute;
  top: 50%;
  @if not $width and not $height {
    transform: translate(-50%, -50%); }
  @else if $width and $height {
    height: $height;
    margin: -($height / 2) #{0 0} -($width / 2);
    width: $width; }
  @else if not $height {
    margin-left: -($width / 2);
    transform: translateY(-50%);
    width: $width; }
  @else {
    height: $height;
    margin-top: -($height / 2);
    transform: translateX(-50%); } }
