// ==================================================
// Page
// ==================================================

*,
*:after,
*:before {
  box-sizing: border-box; }

html {
  height: 100%;
  overflow-x: hidden; }

body {
  -ms-content-zooming: none;
  background-color: #f3f7fb;
  overflow-x: hidden;
  position: relative;
  &.navigation-is-open {
    height: 100%;
    overflow-y: hidden; } }

iframe {
  border: 0; }

// Main

#main {
  position: relative;
  z-index: 2;
  /* fix bug on iOS */
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #f3f7fb;
  /* Force Hardware Acceleration in WebKit */
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform $animation-duration;
  transition-timing-function: cubic-bezier(.91,.01,.6,.99);
  .navigation-is-open & {
    transform: translateX(-100%); } }

// Off-canvas

#offcanvas {
  background-color: map-get($colors, primary);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: visibility 0s $animation-duration;
  visibility: hidden;
  width: 100%;
  z-index: 1;
  .offcanvas-wrapper {
    height: 100%;
    padding: 70px 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* Force Hardware Acceleration in WebKit */
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(50%);
    transition: transform $animation-duration;
    transition-timing-function: cubic-bezier(.86,.01,.77,.78); }
  .navigation-is-open & {
    visibility: visible;
    transition: visibility 0s 0s;
    .offcanvas-wrapper {
      transform: translateX(0);
      transition: transform $animation-duration - 0.2s;
      transition-timing-function: cubic-bezier(.82,.01,.77,.78); } } }

#main-menu {
  margin-bottom: 30px;
  ul {
    margin-bottom: 20px;
    li {
      &.small {
        a {
          @include rem(font-size, 16px);
          @include rem(line-height, 16px);
          font-family: "plutoextralight"; } }
      a {
        @include rem(font-size, 20px);
        @include rem(line-height, 20px);
        color: #fff;
        display: inline-block;
        font-family: "plutoregular";
        margin-bottom: 20px;
        text-decoration: none; }
      ul {
        li {
          margin-left: 20px;
          &:last-of-type a {
            margin-bottom: 20px; }
          a {
            @include rem(font-size, 16px);
            @include rem(line-height, 16px);
            font-family: "plutoextralight";
            margin-bottom: 10px; } } } } } }

// Header

#header {
  background: map-get($colors, secondary);
  background-image: url(../img/header-pattern.png);
  background-image: url(../img/header-pattern.png), linear-gradient(to right, #f1d484 0%, #feecbb 50%, #f1d484 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0 0 7px 2px #a2a2a2;
  height: 70px;
  position: relative; }

#language-switcher {
  position: absolute;
  left: 20px;
  top: 20px;
  li {
    color: map-get($colors, primary);
    float: left;
    &:not(:last-of-type) {
      margin-right: 5px;
      &:after {
        content: "-";
        margin-left: 5px; } }
    a {
      @include rem(font-size, 12px);
      @include rem(line-height, 30px);
      font-family: "plutoregular";
      opacity: 1;
      position: relative;
      text-decoration: none;
      &:not(.active):after {
        background: map-get($colors, primary);
        bottom: -1px;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%; }
      &.active {
        opacity: 0.5; } } } }

.header-logo {
  height: auto;
  left: 50%;
  margin-left: -43px;
  position: absolute;
  top: 5px;
  width: 86px;
  z-index: 1; }

.offcanvas-toggle {
  position: absolute;
  right: 20px;
  top: 15px;
  #offcanvas & {
    top: 20px;
    .icon {
      @include rem(font-size, 30px);
      @include rem(line-height, 30px);
      height: 30px;
      color: #fff; } }
  .icon {
    @include rem(font-size, 40px);
    @include rem(line-height, 40px);
    color: map-get($colors, primary);
    display: block;
    height: 40px; } }

// Footer

#footer-social-networks {
  background-image: linear-gradient(to bottom, #fff, #edf7fd);
  padding: 30px 0;
  text-align: center;
  h2 {
    margin: 0 0 30px; }
  .mentions {
    @include rem(font-size, 12px);
    @include rem(line-height, 15px);
    margin: 20px 0 0; } }

.footer-menu-social-networks {
  margin-bottom: 40px;
  ul {
    li {
      @include rem(font-size, 40px);
      @include rem(line-height, 60px);
      background: #202a35;
      border-radius: 5px;
      color: #edf7fd;
      display: inline-block;
      height: 60px;
      text-align: center;
      width: 60px;
      &:not(:last-of-type) {
        margin-right: 10px; }
      a {
        color: #edf7fd;
        text-decoration: none; } } } }

#mc_embed_signup {
  .mc-field-group {
    float: left;
    input {
      @include rem(line-height, 20px);
      background: #dcdcdc;
      border: none;
      height: 32px;
      margin-left: 160px;
      padding: 6px 12px;
      width: 220px; } }
  input[type=submit] {
    box-shadow: none;
    float: left;
    width: 100px; } }

#footer-infos {
  background: #1a242f;
  border-top: 5px solid #475f73;
  padding: 30px 0;
  text-align: center; }

.footer-logo {
  margin-bottom: 30px; }

.footer-text {
  @include rem(font-size, 12px);
  @include rem(line-height, 24px);
  color: #fff;
  font-family: "plutoregular";
  a {
    color: map-get($colors, yellow); } }

.footer-menu {
  ul {
    li {
      @include rem(font-size, 12px);
      @include rem(line-height, 24px);
      color: map-get($colors, yellow);
      display: inline-block;
      font-family: "plutoregular";
      &:not(:last-of-type) {
        margin-right: 5px;
        &:after {
          content: "-";
          margin-left: 8px; } }
      a {
        color: map-get($colors, yellow);
        text-decoration: none;
        text-transform: uppercase; } } } }
