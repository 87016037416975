// ==================================================
// Class & placeholders helpers
// ==================================================

// Class
// ==================================================

.align-center {
  margin-left: auto;
  margin-right: auto; }

.align-left {
  float: left; }

.align-right {
  float: right; }

.clear-both {
  clear: both; }

.clearfix {
  &::before,
  &::after {
    content: "";
    display: table; }
  &::after {
    clear: both; }
  *zoom: 1; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-underline {
  text-decoration: underline; }

.margin-top-30 {
  margin-top: 30px; }
.margin-bottom-30 {
  margin-bottom: 30px; }

// Placeholders
// ==================================================

%force-hardware-acceleration {
  -webkit-transform: translateZ(0); }
