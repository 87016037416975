// Tablet : >= 640px
// ==================================================
@include susy-breakpoint(640px, 8) {

  .container {
    @include container(640px); }

  // Main

  #main-menu {
    @include span(8); }

  // Wrap

  .wrap {
    .title,
    .more {
      @include span(8); } }

  // Button

  .back-button {
    @include span(8); }

  // Blocks

  .block-product-collection {
    @include span(8); }

  .block-recipe-category {
    @include span(8); }

  .block-news {
    @include span(2);
    &.double {
      @include span(4); } }

  .block-product {
    @include span(8); }

  .block-story {
    @include span(8); }

  .block-recipe {
    @include span(4);
    &.details {
      @include span(8); } }

  .block-shop {
    @include span(8); }

  // Details

  .details-news {
    @include span(8); } }

// Mobile : < 640px
// ==================================================
@include susy-breakpoint(320px 639px, 4) {

  .container {
    @include container(320px); }

  // Main

  #main-menu {
    @include span(4); }

  // Wrap

  .wrap {
    .title,
    .more {
      @include span(4); } }

  // Button

  .back-button {
    @include span(4); }

  // Blocks

  .block-product-collection {
    @include span(4); }

  .block-recipe-category {
    @include span(4); }

  .block-news {
    @include span(2);
    &.double {
      @include span(4); } }

  .block-product {
    @include span(4); }

  .block-story {
    @include span(4); }

  .block-recipe {
    @include span(4);
    &.details {
      @include span(4); }
    .block {
      float: none; }
    .details {
      float: none;
      height: auto;
      padding-top: 30px; } }

  .block-shop {
    @include span(4); }

  // Details

  .details-news {
    @include span(4); }

  // Mailchimp

  #mc_embed_signup {
    .mc-field-group {
      input {
        margin-left: 0; } } } }
